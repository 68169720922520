.megamenu {
  position: static;
}
.megamenu .dropdown-menu {
  width: 100%;
  border: none;
  transition: 0.3s;
  background: none;
  .mega-shadow {
    padding: 30px 0;
    background: #ffffff;
    @media (max-width: 991.98px) {
      padding: 10px 0;
    }
  }
  .megamenu-box {
    margin: 0 30px;
    gap: 30px;
    display: flex;
    justify-content: space-evenly;
    background: #ffffff;
    @media (max-width: 991.98px) {
      flex-direction: column;
    }
  }
  .megamenu-box > div {
    flex: 1;
  }
}
.navbar {
  top: 0;
  padding: 0;
  transition: 0.3s;
  position: static;
  z-index: 9999;
  background: rgba(220, 255, 219, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  @media (min-width: 991.98px) {
    position: sticky;
  }
  .container-fluid {
    padding: 0;
  }
  .navbar-brand {
    display: flex;
    padding: 0 16px;
    align-self: stretch;
    align-items: center;
  }
  .navbar-nav {
    .nav-link {
      margin: 0 5px;
      border-radius: 4px;
      color: var(--color-1);
      font: 400 14px inherit;
      &:hover {
        color: var(--color-1);
        background: rgba(0, 0, 0, 0.1);
      }
      &:active {
        background: rgba(0, 0, 0, 0.2);
        color: var(--color-2);
      }
      @media (max-width: 991.98px) {
        margin: 5px 0;
      }
    }
    .dropdown {
      .dropdown-menu {
        .dropdown-item {
          padding: 4px 8px;
          border-radius: 4px;
          font: 500 14px inherit;
          &:hover {
            background: rgba(0, 0, 0, 0.07);
          }
          &:focus {
            color: #000;
          }
        }
      }
      @media (max-width: 991.98px) {
        .dropdown-menu {
          .dropdown-item {
            color: var(--color-2);
            &.active {
              background: transparent;
            }
            &:hover {
              background: rgba(255, 255, 255, 0.1);
            }
          }
        }
        &:hover .dropdown-menu {
          transform: scale(1);
        }
      }
    }
  }
  .navbar-cta {
    background: var(--color-primary);
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    border-radius: 99px;
    &:hover {
      filter: brightness(0.85);
    }
    &:active {
      filter: brightness(0.75);
    }
  }
  @media (max-width: 991.98px) {
    .navbar-cta {
      margin: auto;
    }
  }
  .navbar-toggler {
    border: 0;
    line-height: 0;
    @media (max-width: 991.98px) {
      order: 2;
      margin: 0 8px;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .navbar-collapse {
    @media (max-width: 991.98px) {
      order: 3;
    }
  }
  .nav-heading {
    gap: 6px;
    display: flex;
    align-items: center;
    font: 600 1rem system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
}
.dropdown-menu[data-bs-popper] {
  top: 87% !important;
}
.col_bg1,
.col_bg2,
.col_bg3,
.col_bg4,
.col_bg5 {
  padding: 10px;
  border-radius: 10px;
  background: no-repeat center/cover;
}
.col_bg1 {
  background-color: hsl(58, 100%, 99%);
  background-image: url(../../images/bg1.png);
}
.col_bg2 {
  background-color: hsl(252, 100%, 99%);
  background-image: url(../../images/bg2.png);
}
.col_bg3 {
  background-color: hsl(21, 78%, 99%);
  background-image: url(../../images/bg3.png);
}
.col_bg4 {
  background-color: hsl(118, 100%, 99%);
  background-image: url(../../images/bg4.png);
}
.col_bg5 {
  // background-color: ;
  background-image: url(../../images/bgdescription.png);
}
.nav_career_bg {
  font-size: 1.2rem;
  background: url(../../images/bgcareers.svg) no-repeat center/cover;
}
.navbarImg {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.navbarLogo {
  height: 32px;
  @media (min-width: 640px) {
    height: 40px;
  }
  @media (min-width: 768px) {
    height: 44px;
  }
  @media (min-width: 1024px) {
    height: 48px;
  }
}
