.brand-section-container {
  border-bottom: 1px solid var(--border-brutal);
}
.brand-section {
  padding-bottom: 50px;
  @media (max-width: 991.98px) {
    padding-bottom: 15px;
  }
  .brands-container {
    gap: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: 640px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 768px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(7, 1fr);
    }
    .brand {
      display: flex;
      align-items: center;
      justify-content: center;
      .img-fluid {
        width: 100px;
        height: auto;
      }
    }
  }
  &.bordered {
    padding-top: 80px;
    @media (max-width: 991.98px) {
      padding-top: 40px;
    }
    border-top: 1px solid var(--border-brutal);
  }
  &.pt-60 {
    padding-top: 60px;
  }
}
