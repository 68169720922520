.landing-3 {
  .hero-image-container {
    img {
      height: calc(3rem + 53vw);
      margin-top: calc(3rem + 4vw);
      object-fit: cover;
      object-position: center;
      width: 100%;
      border-radius: 15px;
      box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
      @media (min-width: 1200px) {
        margin-top: 100px;
        height: 680px;
      }
    }
  }
  .capability-card {
    .count {
      font-size: 18px;
      font-weight: 700;
      color: #fefefe;
      background: var(--color-primary);
      border-radius: 50%;
      height: 30px;
      min-width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
    }
  }
  .about-img-container {
    box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
    transition: 0.3s;
    @media (min-width: 992px) {
      max-width: 500px;
    }
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    img {
      height: calc(3rem + 40vw);
      @media (min-width: 992px) {
        height: calc(1rem + 25vw);
      }
      @media (min-width: 1200px) {
        height: 340px;
      }
      object-fit: cover;
      object-position: center;
      width: 100%;
      transition: 0.3s;
    }
    a {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;
      }
      &:hover {
        img {
          filter: brightness(0.8);
        }
        svg {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
    &:hover {
      box-shadow: 0 0 0 1px var(--border-brutal);
    }
  }
  .counter-section {
    padding: 20px 30px;
  }
  .info-banner-image {
    width: 500px;
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
}


// Carousel Test

            @keyframes logoSlider {
                from {
                    transform: translate(0);
                }
                to {
                    transform: translate(-100%);
                }
            }

            .logo-slider {
                display: inline-block;

                animation: logoSlider 3s 1s infinite linear;
            }

            .logo-slider img {
                margin: 0 40px;
            }

            .slider-clients {
                white-space: nowrap;
                overflow: hidden;
                padding: 40px 0;
                width: 100%;
                margin: auto;

                position: relative;
            }

            .slider-clients::before,
            .slider-clients::after {
                position: absolute;
                top: 0;
                width: 150px;
                height: 100%;
                content: "";
                z-index: 2;
            }

            .slider-clients::before {
                left: 0;
                // background: linear-gradient(
                //     to left,
                //     rgba(255, 255, 255, 0),
                //     white
                // );
            }

            .slider-clients::after {
                right: 0;
                // background: linear-gradient(
                //     to right,
                //     rgba(255, 255, 255, 0),
                //     white
                // );
            }


// Accordion custom CSS
  .cta-form {
    background: #fefefe;
    border-radius: 13px;
    // overflow: hidden;
    padding: 5px;
    flex-wrap: wrap;
    box-shadow: 0 0 0 1px var(--border-brutal);
    @media (max-width: 575.98px) {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }
    .cta-input {
      flex-grow: 1;
      @media (max-width: 575.98px) {
        margin-bottom: 16px;
      }
      input {
        border: 0;
        width: 100%;
        border-radius: 10px;
        line-height: 48px;
        padding: 0 5px 0 25px;
        box-shadow: none;
        // &:focus{box-shadow: 0 0 0 1px var(--border-brutal);}
        @media (max-width: 575.98px) {
          box-shadow: 0 0 0 1px var(--border-brutal);
        }
      }
    }
    .cta-btn {
      @media (max-width: 575.98px) {
        width: 100%;
        display: flex;
      }
      .btn {
        width: 100%;
      }
    }
  }

  .accordion-button {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  .accordion-item {
    border-bottom: 1px solid var(--border-brutal);
  }
  .accordion-button {
    background: #DCFFDB;
    font-size: 18px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    &:focus {
      box-shadow: none;
      // color: var(--color-1);
    }
    &:not(.collapsed) {
      color: var(--color-1);
      box-shadow: none;
    }
  }
  .accordion-body {
    background: var(--bg-shade-green);
    color: var(--color-1);
    font-size: 18px;
    line-height: 1.5;
  }

  .accordion-button:focus{
    background: var(--bg-shade-green);
  }


  // Custom css for tableData

  /*------------------------------------*\
  #TABLE
\*------------------------------------*/

.table td, .table th {
  border-top: none;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(155,0,0,.03);
}
.table-striped tbody tr:nth-of-type(even) {
  // background-color: rgb(247, 247, 247);
}

.table .thead-light th {
  color: #495057;
  background-color: #ffffff;
  // border-bottom: 1px solid #dee2e6;
}

.table .thead-light th ,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  // border: 5px solid rgb(255, 255, 255);
}


/*------------------------------------*\
  #BRZE STATISTIKE
\*------------------------------------*/
.brze_statistike th {
  color: #e94057;
  background-color: rgb(100, 20, 40, 0.05);
  font-size: 1.5rem;
  padding: .5rem 0.5rem 0.5rem 0.5rem;
  font-family: Caveat, sans-serif;
}

.brze_statistike tr td:nth-child(2) {
  font-size: 0.8rem;
  font-weight: 400;
  // color: #7e4545;
}

.brze_statistike tr td:nth-child(2) span {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  // color: #7e4545;
  // border: 2px solid red;
}

.brze_statistike tr td:last-of-type .fa-smile-o {
  color: #54b535;
  font-size: 2rem;
  padding: .5rem 0rem 0.5rem 0rem;
}

.brze_statistike tr td:last-of-type .fa-meh-o {
  color: #b54435;
  font-size: 2rem;
  padding: .5rem 0rem 0.5rem 0rem;
}

.tableDiv{
  padding: 30px;
  background: white;
  border-radius: 10px,
}


@media screen and (max-width: 600px) {
  .tableDiv{
    padding: 0px;
    overflow-x: scroll;
   }
  .handleResponsive{
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .custom-div-handle{
    flex-direction: column-reverse;
  }
  .form-control:active {
      box-shadow: 0 0 0 3px rgba(0, 170, 69, 0.35);
  }
}

.accordion-button{
  background-color: #DCFFDB !important;
}
