.blog-card{
    background: #fefefe;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    transition: 0.3s;
    box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
    .body{
        padding: 24px;
    }
    &:hover{
        box-shadow: 0 0 0 1px var(--border-brutal);
    }
    .thumb img{
        width: 100%;
        object-fit: cover;
        aspect-ratio: 16/9;
        object-position: center;
    }
}
.blog_link{
    text-decoration: none;
    color: var(--color-1);
    &:hover{
        color: var(--color-1);
    }
}