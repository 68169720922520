.consulting {
  .cta-form {
    background: #fefefe;
    border-radius: 13px;
    // overflow: hidden;
    padding: 5px;
    flex-wrap: wrap;
    box-shadow: 0 0 0 1px var(--border-brutal);
    @media (max-width: 575.98px) {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }
    .cta-input {
      flex-grow: 1;
      @media (max-width: 575.98px) {
        margin-bottom: 16px;
      }
      input {
        border: 0;
        width: 100%;
        border-radius: 10px;
        line-height: 48px;
        padding: 0 5px 0 25px;
        box-shadow: none;
        // &:focus{box-shadow: 0 0 0 1px var(--border-brutal);}
        @media (max-width: 575.98px) {
          box-shadow: 0 0 0 1px var(--border-brutal);
        }
      }
    }
    .cta-btn {
      @media (max-width: 575.98px) {
        width: 100%;
        display: flex;
      }
      .btn {
        width: 100%;
      }
    }
  }

  .accordion-button {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  .accordion-item {
    border-bottom: 1px solid var(--border-brutal);
  }
  .accordion-button {
    background: var(--bg-shade-green);
    font-size: 18px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    &:focus {
      box-shadow: none;
      // color: var(--color-1);
    }
    &:not(.collapsed) {
      color: var(--color-1);
      box-shadow: none;
    }
  }
  .accordion-body {
    background: var(--bg-shade-green);
    color: var(--color-1);
    font-size: 18px;
    line-height: 1.5;
  }
}
.home-hero {
  background-image: radial-gradient(
    ellipse at bottom left,
    rgba(15, 169, 88, 0.4),
    transparent 30%
  );
}
.home_hero_subheading {
  font-size: 1.3rem;
}
.ourParnersSection {
  padding: 50px 0;
  // border-top: 1px solid;
  border-bottom: 1px solid;
  .ourParners {
    display: flex;
    div {
      p {
        color: #393939;
        font-size: 17px;
        font-weight: 600;
        margin-top: 10px;
      }
      .google {
        fill: #4285f4;
      }
      .meta {
        color: #0467df;
      }
      .instagram {
        fill: #e4405f;
      }
      .bing {
        fill: #258ffa;
      }
      .youtube {
        fill: #f00;
      }
      .facebook {
        fill: #0467df;
      }
      .twitter {
        fill: #1da1f2;
      }
    }
  }
}
.animeHeading1 {
  font-size: 3rem !important;
  line-height: 1.2;
  @media (max-width: 991.98px) {
    font-size: 2.1rem !important;
  }
}
.home_map {
  border-radius: 15px;
  border: 1px solid;
  // box-shadow: 0 0 0 1px var(--border-brutal), 4px 4px 0 var(--border-brutal);
}
.homeIcon {
  border: 2px solid;
  border-radius: 15px;
  box-shadow: 3px 3px black;
}
