.blog-article-container {
  .blog-article {
    img {
      border-radius: 15px;
      box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
      aspect-ratio: 16/9;
      object-fit: cover;
      object-position: center;
    }
    img:first-child{
      width: 100%;
    }
  }
  .blog-article-section {
    max-width: 692px;
    margin-left: auto;
    margin-right: auto;
    p {
      margin: 13px 0;
      font-size: 20px;
      color: var(--color-1);
      line-height: 1.5;
    }
    h2 {
      margin-top: 30px;
      margin-bottom: 16px;
      font-family: var(--heading-font);
      font-size: calc(1.325rem + 0.9vw);
      line-height: 1.2;
    }
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font);
    }
  }
}
