// include blogArticle, pages, internalPagesDetails, subpages, subsubpages
.project-detail-container {
  .case-study {
    img {
      aspect-ratio: 16/9;
      object-fit: cover;
      object-position: center;
      border-radius: 15px;
      box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
    }
    img:first-child{
      width: 100%;
    }
  }
  .case-study-section {
    max-width: 692px;
    margin-left: auto;
    margin-right: auto;
    p {
      margin: 13px 0;
      font-size: 20px;
    }
    h2 {
      margin-top: 30px;
      font-size: calc(1.325rem + 0.9vw);
      line-height: 1.2;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--heading-font);
    }
  }
}


.mainPageArticleImage {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}
