.testimonial-card {
  background: #ffffff;
  padding: 24px 20px;
  border-radius: 15px;
  height: 100%;
  text-decoration: none;
  box-shadow: 0 0 0 1px var(--border-brutal), 4px 4px 0 var(--border-brutal);
}
.youtube {
  &:hover {
    background: white url(../../images/youtube.png) no-repeat right/cover;
    box-shadow: 0 0 0 1px red, 4px 4px 0 red;
  }
}
.testimonial-page-card {
  background: #ffffff;
  padding: 24px 20px;
  border-radius: 15px;
  height: 100%;
  text-decoration: none;
  box-shadow: 0 0 0 1px var(--border-brutal), 4px 4px 0 var(--border-brutal);
}
.testimonial-user {
  .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
