.top_header {
  background: #0fa958;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  @media (min-width: 991.98px) {
    background: rgb(220, 255, 219);
  }
  .text-white {
    @media (min-width: 991.98px) {
      color: #000 !important;
    }
  }
  div .top-div {
    cursor: pointer;
    svg {
      @media (min-width: 991.98px) {
        fill: #0fa958;
      }
    }
    span {
      font-weight: 600;
    }
  }
  .phonenumber {
    @media (max-width: 991.98px) {
      display: none !important;
      &:nth-of-type(1) {
        display: flex !important;
      }
    }
  }
}
